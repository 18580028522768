body .slick-list
{
    position: relative;
    
    display: block;
    overflow: visible !important;
    overflow-x: visible !important;
    margin: 0;
    padding: 0px;
}
.slick-track
{
    position: relative;
    top: 0;
    left: 0;
    height: 165px !important;
    display: block;
    margin-left: 40px !important;
    margin-right: 30px !important;
    display: flex !important;
    align-items: center !important;
}
.slick-slide
{
    
    min-width: 220px !important;
}
body .slick-next
{
    top: 40% !important;
    right: 0px !important;
    /* background: transparent linear-gradient(to left, #030303 0%, #00000000 100%) 0% 0% no-repeat padding-box;; */
    height: 80%;
    width: 40px;
    padding: 0px 12px;
     opacity: 0.5;
    padding-right: 20px;
    transition: 300ms ease;

}
body .slick-prev
{
    left: 0px !important;
    top: 40% !important;
    z-index: 100 !important;
    opacity: 0.3;
    /* background: transparent linear-gradient(to right, #030303 0%, #00000000 100%) 0% 0% no-repeat padding-box;; */
    height: 80%;
    width: 40px;
    padding: 0px 12px;
    transition: 300ms ease;
    
}
body .slick-next:hover{
    opacity: 1;
    /* background: transparent linear-gradient(to left, #030303 0%, #00000000 100%) 0% 0% no-repeat padding-box;; */

    /* background: rgba(0,0,0,0.8) !important; */
    
}
body .slick-prev:hover{
    opacity: 0.5;
    /* background: transparent linear-gradient(to right, #030303 0%, #00000000 100%) 0% 0% no-repeat padding-box; */
    /* background: rgba(0,0,0,0.8) !important; */
}
/* body .slick-prev::before{
width: 16px;
height: 31px;
transform: matrix(0, -1, 1, 0, 0, 0);
border: 4px solid #FFFFFF;
opacity: 1;
content: '';
} */